import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http'
import { HttpService } from '../http/http.service'

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  constructor(private http: HttpClient, private services: HttpService) {}

  uploadRequestFile(fileObj, draftId): Observable<any> {
    let formData: any = new FormData()
    formData.append('file', fileObj.file)
    formData.append('draftId', draftId)
    formData.append('tempId', fileObj.tempId)

    let options
    if (this.services.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.services.token}`)
        .set('Role', `${this.services.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    return this.http
      .post('/api/apiv1/draft/upload_file_draft', formData, {
        reportProgress: true,
        observe: 'events',
        headers: options,
      })
      .pipe
      // catchError(this.errorMgmt)
      ()
  }

  uploadRequestFile1(file, draftId): Observable<any> {
    let formData: any = new FormData()
    formData.append('file', file)
    formData.append('draftId', draftId)
    // formData.append('tempId', fileObj.tempId)

    let options
    if (this.services.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.services.token}`)
        .set('Role', `${this.services.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    return this.http
      .post('/api/apiv1/draft/upload_file_draft', formData, {
        reportProgress: true,
        observe: 'events',
        headers: options,
      })
      .pipe
      // catchError(this.errorMgmt)
      ()
  }
}
